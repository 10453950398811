import login from "../../../assets/images/login.png";


const LogoContainer = () => {

    return <div className="text-center bg-white border py-2 rounded-3">
    <img className="rounded-3" width="50%" src={login} alt="" />
  </div>
}


export default LogoContainer;